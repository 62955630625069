import { IPopupDispatcher, IPaylodAction, PopupActions } from "../interfaces/IPopupDispatcher";
import { IPopupSettings } from "../interfaces/reducers/IAppStore";


class PopupDispatcher implements IPopupDispatcher {
	constructor(){
        this.addPopups = this.addPopups.bind(this);
        this.removePopups = this.removePopups.bind(this);
	}
	public addPopups(popupSettings:{[id: string]: IPopupSettings}) {
		return (dispatch: (data: IPaylodAction<{[id: string]: IPopupSettings}>) => void) => {
			dispatch({
				type: PopupActions.ADD_POPUPS,
				payload: popupSettings
			})
		}
    }
    
    public removePopups(popupKeys: Array<string>) {
		return (dispatch: (data: IPaylodAction<Array<string>>) => void) => {
			dispatch({
				type: PopupActions.REMOVE_POPUPS,
				payload: popupKeys
			})
		}
	}
}

export const popupDispatcher = new PopupDispatcher() as IPopupDispatcher;
import * as React from "react";
import axios from "axios";
import appConfig from "../reducers/appConfig";
import { IUser } from "../interfaces/reducers/IAppStore";
import OverlayLoader from "../Loading";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { IPopupDispatcher } from "../interfaces/IPopupDispatcher";
import { UserViewModel } from "../interfaces/UserViewMode";
import SetNewPass from "./SetNewPass";
import EditRoles from "./EditRoles";

interface Props {
    user: IUser;
    back: () => void;
    popupDispatcher: IPopupDispatcher;
}



interface State {
    loading: boolean;
    allUsers: Array<UserViewModel>
}

class UserRegister extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            allUsers: []
        }
    }

    componentWillMount() {
        this.refreshUsers();
    }

    refreshUsers = () => {
        this.setState({...this.state, loading: true})
        axios.get(appConfig.apiUrl + "SmartBurgasAccount/getUsers").then(r => {
            this.setState({ ...this.state, allUsers: r.data, loading: false })
        })
        .catch(e => {
            this.setState({ ...this.state, loading: false })
        })
    }

    toggleActive = (user: UserViewModel) => {
        this.setState({...this.state, loading: true})
        axios.post(appConfig.apiUrl + "SmartBurgasAccount/toggleActiveteUser", {
            Username: user.Username,
            Activate: !user.Active
        }).then(r => {
            this.refreshUsers();
        })
        .catch(e => {
            this.setState({ ...this.state, loading: false })
        })
    }

    roleAdministrator = {
        admin: ["ca", "opu", "contractoradmin", "rps", "contractoruser"],
        ca: ["opu", "contractoradmin"],
        opu: ["rps"],
        contractoradmin: ["contractoruser"]
    }
    roleToAlias = {
        ca: "Потребители от централна администрация",
        opu: "Потребители от ОПУ",
        rps: "Потребители от РПС",
        contractoruser: "Потребители на фирма изпълнител",
        contractoradmin: "Администратори на фирма изпълнител",
    } as {[key: string]: string}

    getUsersByRole(role: string){
        role = role.toUpperCase();
        let users: Array<JSX.Element> = [];
        this.state.allUsers.forEach((user, i) => {
            var roles = user.Roles.map(r => r.toUpperCase());
            if((roles.indexOf(role) != -1 || !role) && user.Username != this.props.user.username){
                users.push(
                    <tr key={i}>
                        <td>
                            {user.Username}
                        </td>
                        <td>
                            {user.Name}
                        </td>
                        <td>
                            {user.Roles.map(r => appConfig.roles[r]).join(", ")}
                        </td>
                        <td style={{width: "100px"}}>
                            <button className={"form-control " + (user.Active ? "btn-danger" : "btn-success")}
                                onClick={() => {
                                    this.toggleActive(user);
                                }}>{user.Active ? "Деактивирай" : "Активирай"}</button>
                        </td>
                        <td style={{width: "220px"}}>
                            <button className={"form-control btn-danger"}
                                onClick={() => {
                                    this.props.popupDispatcher.addPopups({
                                        setNewPass: {
                                            width: "500px",
                                            content: <SetNewPass user={user}/>,
                                            header: <div>Задай нова парола</div>
                                        }
                                    })
                                }}>Задай нова парола</button>
                        </td>
                        <td style={{width: "200px"}}>
                            <button className={"form-control btn-danger"}
                                onClick={() => {
                                    this.props.popupDispatcher.addPopups({
                                        setNewPass: {
                                            width: "500px",
                                            content: <EditRoles user={user}/>,
                                            header: <div>Редактирай роли</div>
                                        }
                                    })
                                }}>Редактирай роли</button>
                        </td>
                    </tr>
                )
            }
        })
        if(users.length == 0){
            return (
                <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "16px"}}>Няма потребители</div>
            )
        }
        return (
            <table style={{width: "100%"}} className="table table-striped">
                    
                <tbody>
                    <tr>
                        <th>Потребителско име</th>
                        <th>Име</th>
                        <th>Роли</th>
                        <th colSpan={3}>Инструменти</th>
                    </tr>
                    {users}
                </tbody>
            </table>
        )
    }

    usersControl = () => {
        // var rolesToManage: string[] = [];
        // if(this.props.user.isAdmin){
        //     rolesToManage = this.roleAdministrator["admin"]
        // }
        // else if(this.props.user.isCA){
        //     rolesToManage = this.roleAdministrator["ca"]
        // }
        // else if(this.props.user.isContractorAdmin){
        //     rolesToManage = this.roleAdministrator["contractoradmin"]
        // }
        // else if(this.props.user.isOPU){
        //     rolesToManage = this.roleAdministrator["opu"]
        // }
        // let tabs: JSX.Element[] = [];
        // let tabContents: JSX.Element[] = [];
        // rolesToManage.forEach((r, i) => {
        //     tabs.push(<Tab key={i}>{this.roleToAlias[r]}</Tab>)
        //     tabContents.push(<TabPanel key={i}>{this.getUsersByRole(r)}</TabPanel>)
        // })
        
        // return (
        //     <Tabs>
        //         <TabList>
        //             {tabs}
        //         </TabList>
        //         {tabContents}
        //     </Tabs>
        // )
        return this.getUsersByRole("");
    }

    render() {  
        return (
            <div style={{ display: "flex", flexDirection: "column", textAlign: "center", margin: "50px 100px 0 100px" }}>
                <OverlayLoader size="30px" show={this.state.loading} />
                <div style={{display: "flex", alignItems: "center", margin: "15px", justifyContent: "space-around", width: "400px"}}>
                    <button className="btn btn-secondary" onClick={this.props.back}>{"<< Назад"}</button>
                    <div>Регистър на потребителите</div>
                </div>
                {this.usersControl()}
            </div>
        )
    }
}

export default UserRegister;
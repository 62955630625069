import * as React from "react";
import appConfig from "../reducers/appConfig";
import { IUser } from "../interfaces/reducers/IAppStore";
import axios from "axios";
interface State {
    password: string;
    oldPassword: string;
    passwordConf: string;
    canRegister: boolean;
    errorMsg: string;
    success: boolean;
}

interface Props{
    user: IUser;
}

class ChangePassword extends React.Component<Props, State> {
    constructor(props: Props){
        super(props);
        this.state = {
            oldPassword: "",
            password: "",
            passwordConf: "",
            canRegister: false,
            errorMsg: "",
            success: false
        }
    }

    getErrorTranslated = (error: string) => {
        if(error.indexOf("Passwords must have ") != -1){
            return "Паролата трябва да е поне 8 символа и да съдържа малки букви, големи букви, цифра или специален символ";
        }
        switch(error){
            case "Incorrect password.":
                return "Грешна парола"
            default:
                return "Грешна парола"
        }
    }

    changePassword = () => {
        this.setState({...this.state, errorMsg: "", success: false});
        if(!this.state.oldPassword || !this.state.password || !this.state.passwordConf){
            this.setState({...this.state, errorMsg: "Моля попълнете старата и новата парола", success: false});
            return;
        }
        if(this.state.password != this.state.passwordConf){
            this.setState({...this.state, errorMsg: "Паролата за потвърждение не е същата", success: false})
            return;
        }
        return new Promise((resolve, reject) => {
            axios.post(appConfig.apiUrl + "SmartBurgasAccount/ChangePassword", {
                OldPassword: this.state.oldPassword,
                Password: this.state.password,
                PasswordConfirmation: this.state.passwordConf
            })
            .then(r => {
                this.setState({...this.state, errorMsg: "", success: true})
            })
            .catch((e: any) => {
                console.warn(e.response)
                if(e.response && e.response.data && Object.keys(e.response.data).length > 0){
                    if(e.response.data.Errors){
                        this.setState({...this.state, errorMsg: this.getErrorTranslated(e.response.data.Errors[0].Description), success: false})
                    }
                    else{
                        this.setState({...this.state, errorMsg: e.response.data[Object.keys(e.response.data)[0]][0], success: false})
                    }
                   
                }
                else{
                    this.setState({...this.state, errorMsg: "Възникна грешка", success: false})
                }
            })
        })
    }
    render(){
        if(this.state.success){
            return (
                <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <div style={{color: "green"}}>{`Успешна регистрация на администраторски акаунт ${this.props.user.username}`}</div>
                    <button className="btn btn-success" onClick={() => this.setState({...this.state, errorMsg: "", success: false, oldPassword: "", password: "", passwordConf: ""})}>Смени паролата отново</button>
                </div>
            )
        }
        return(
            <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                 <input
                    id="oldPassword"
                    value={this.state.oldPassword}
                    placeholder="Стара парола"
                    type="password"
                    onChange={e => {
                        this.setState({ ...this.state, oldPassword: e.target.value });
                    }}
                    onKeyUp={(e) => {
                        if(e.keyCode == 13){
                            this.changePassword();
                        }
                    }}
                    className="form-control" />
                <input
                    id="password"
                    value={this.state.password}
                    placeholder="Нова парола"
                    onChange={e => {
                        this.setState({ ...this.state, password: e.target.value });
                    }}
                    onKeyUp={(e) => {
                        if(e.keyCode == 13){
                            this.changePassword();
                        }
                    }}
                    type="password"
                    className="form-control" />
                <input
                    id="confirmationPassword"
                    value={this.state.passwordConf}
                    placeholder="Потвърдете нова паролата"
                    onChange={e => {
                        this.setState({ ...this.state, passwordConf: e.target.value });
                    }}
                    onKeyUp={(e) => {
                        if(e.keyCode == 13){
                            this.changePassword();
                        }
                    }}
                    type="password"
                    className="form-control" />
                {this.state.errorMsg ? <div style={{color: "red"}}>{this.state.errorMsg}</div> : null}
                <button className="btn btn-success" onClick={() => {
                    // if (this.state.oldPassword && this.state.password && this.state.passwordConf) {
                        this.changePassword();
                    // }
                }}>Смени паролата</button>
            </div>
        )
    }
}

export default ChangePassword;
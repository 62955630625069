
import { IAppStore, IUserInfo } from '../interfaces/reducers/IAppStore';
import { UserInfoAction } from '../enums/ActionTypes/UserInfoAction';

let initState = {
     loading: false
} as IUserInfo;
const userInfo = (state: IUserInfo = initState, action: {type: string, payload: any}) => {
     switch ( action. type) {
         case UserInfoAction.SET_LOADING:
             return { ...state, loading: action. payload }
         case UserInfoAction.SIGN_IN:
             return { ...action.payload }
         case UserInfoAction.SIGN_OUT:
             return { ...initState }
         case UserInfoAction.CLEAR_ERROR:
             return { ...state, signInError: undefined, loading: false }
         case UserInfoAction.ERROR_SIGNIN:
             return { ...state, signInError: action.payload, loading: false }
         default:
             return state;
    }
}
export default userInfo;
import { IPopupSettings } from "./reducers/IAppStore";

export interface IPopupDispatcher {
	addPopups(popupSettings:{[id: string]: IPopupSettings}): (dispatch: (data: IPaylodAction) => void) => void;
	removePopups(popupKeys: Array<string>): (dispatch: (data: IPaylodAction) => void) => void;
}
export interface IPaylodAction<T = any>  extends IActions {
    payload: T
}
export interface IActionDispatcher {

}
export enum PopupActions {
    ADD_POPUPS = "[popups] Add",
    REMOVE_POPUPS = "[popups] Remove"
}

export let BaseActions = PopupActions.ADD_POPUPS as PopupActions;


interface IActions {
    type: typeof BaseActions;
}
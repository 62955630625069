import * as React from "react";
import { connect } from "react-redux";
import { singIn } from '../actions/userInfo';
import { IAppStore, IUserInfo, IUser } from '../interfaces/reducers/IAppStore';
import OverlayLoader from '../Loading';
import axios from "axios";
import appConfig from "../reducers/appConfig";


interface OwnProps {
    user?: IUser;
}

interface DispatchProps {
    singIn: typeof singIn;
}

interface ParentProps {
    register: () => void;
}

interface State {
    username: string;
    password: string;
    name: string;
    passwordConf: string;
    canRegister: boolean;
    errorMsg: string;
    success: boolean;
    loading: boolean;
}

type Props = ParentProps & DispatchProps & OwnProps;

class RegisterView extends React.Component<Props, State>{
    constructor(props: Props){
        super(props);
        this.state = {
            username: "",
            password: "",
            passwordConf: "",
            name: "",
            canRegister: false,
            errorMsg: "",
            success: false,
            loading: false
        }
    }

    componentWillReceiveProps(props: Props){
        if(props.user){
            window.location.href = window.location.origin + window.location.pathname;
        }
    }
    componentWillMount(){
        axios.get(appConfig.apiUrl + "SmartBurgasAccount/canRegister").then(r => {
            this.setState({...this.state, canRegister: r.data})
        })
    }

    registerUser = () => {
        this.setState({...this.state, errorMsg: "", success: false, loading: true})
        if(this.state.password != this.state.passwordConf){
            this.setState({...this.state, errorMsg: "Паролата за потвърждение не е същата", success: false, loading: false})
            return;
        }
        return new Promise((resolve, reject) => {
            axios.post(appConfig.apiUrl + "Account/Register", {
                UserName: this.state.username,
                Password: this.state.password,
                PasswordConfirmation: this.state.passwordConf,
                Name: this.state.name
            })
            .then(r => {
                this.props.singIn(this.state.username, this.state.password);
                this.setState({...this.state, errorMsg: "", success: true, loading: false})
            })
            .catch((e: any) => {
                if(e.response && e.response.data && Object.keys(e.response.data).length > 0){
                    
                    this.setState({...this.state, errorMsg: e.response.data[Object.keys(e.response.data)[0]][0], success: false, loading: false})
                }
                else{
                    this.setState({...this.state, errorMsg: "Възникна грешка", success: false, loading: false})
                }
            })
        })
    }

    render(){
        if(!this.state.canRegister){
            return <div>Регистрацята не е активна</div>
        }
        if(this.state.success){
            return (
                <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <div style={{color: "green"}}>{`Успешна регистрация на администраторски акаунт ${this.state.username}`}</div>
                    <button className="btn btn-success" onClick={this.props.register}>Вход в системата</button>
                </div>
            )
        }
        return (
            <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                <button className="btn btn-success" onClick={this.props.register}>Вход в системата</button>
                <OverlayLoader size="30px" show={this.state.loading} />
                <input
                    id="name"
                    value={this.state.name}
                    placeholder="Име"
                    onChange={e => {
                        this.setState({ ...this.state, name: e.target.value });
                    }}
                    className="form-control" />
                <input
                    id="username"
                    value={this.state.username}
                    placeholder="Потребителско име"
                    onChange={e => {
                        this.setState({ ...this.state, username: e.target.value });
                    }}
                    onKeyUp={(e) => {
                        if(e.keyCode == 13 && this.state.username && this.state.password && this.state.passwordConf){
                            this.registerUser();
                        }
                    }}
                    className="form-control" />
                <input
                    id="password"
                    value={this.state.password}
                    placeholder="Парола"
                    onChange={e => {
                        this.setState({ ...this.state, password: e.target.value });
                    }}
                    onKeyUp={(e) => {
                        if(e.keyCode == 13 && this.state.username && this.state.password && this.state.passwordConf){
                            this.registerUser();
                        }
                    }}
                    type="password"
                    className="form-control" />
                <input
                    id="confirmationPassword"
                    value={this.state.passwordConf}
                    placeholder="Потвърдете паролата"
                    onChange={e => {
                        this.setState({ ...this.state, passwordConf: e.target.value });
                    }}
                    onKeyUp={(e) => {
                        if(e.keyCode == 13 && this.state.username && this.state.password && this.state.passwordConf){
                            this.registerUser();
                        }
                    }}
                    type="password"
                    className="form-control" />
                {this.state.errorMsg ? <div style={{color: "red"}}>{this.state.errorMsg}</div> : null}
                <button className="btn btn-success" onClick={() => {
                    if (this.state.username && this.state.password && this.state.passwordConf) {
                        this.registerUser();
                    }
                }}>Регистрация</button>
            </div>
        )
    }
}

const mapStateToProps = (state: IAppStore) => ({
    user: state.userInfo.user
})

export default connect<OwnProps, DispatchProps, ParentProps, IAppStore>(mapStateToProps, {singIn})(RegisterView as any);
import * as React from 'react';
import logo from './logo.svg';
import './App.css';
import axios from "axios";
import { tryRecoverCredentials, signOut } from './actions/userInfo';
import { connect } from 'react-redux';
import { IAppStore, IUserInfo } from './interfaces/reducers/IAppStore';
import LoginRegisterSplit from './components/LoginRegisterSplit';
import Content from './components/Content';
import "react-tabs/style/react-tabs.css";
import FixedPopupRenderer from './FixedPopupRenderer';
import { popupDispatcher } from './actions/popupDispatcher';
import { IPopupDispatcher } from './interfaces/IPopupDispatcher';

interface State {
	
}

interface OwnProps {
	userInfo: IUserInfo;
}
interface ParentProps {
	
}

interface DispatchProps extends IPopupDispatcher {
	tryRecoverCredentials: typeof tryRecoverCredentials;
	signOut: typeof signOut;
}

type Props = OwnProps & DispatchProps & ParentProps;
class App extends React.Component<Props, State> {
	constructor(props: Props){
		super(props);
		var search = location.search.substring(1);
		var queryObject = {} as {[key: string]: string};
		if(search){
			queryObject = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
		}
		this.state = {
			
		}
	}
	componentWillMount(){
		this.props.tryRecoverCredentials((success) => {

		})
	}
	render() {
		return (
			<main>
				<FixedPopupRenderer />
				<header>Управление на потребители</header>
				<section>
					{
						this.props.userInfo.user ? 
						<Content popupDispatcher={this.props} user={this.props.userInfo.user} signOut={this.props.signOut}></Content>
						:
						<LoginRegisterSplit></LoginRegisterSplit>
					}
				</section>
			</main>
		);
	}
}

const mapStateToProps = (state: IAppStore): OwnProps => ({
	userInfo: state.userInfo
})

export default connect<OwnProps, DispatchProps, ParentProps, IAppStore>(mapStateToProps, {tryRecoverCredentials, signOut, ...popupDispatcher})(App as any);

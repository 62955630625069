
import { IAppStore, IUserInfo, IPopupSettings, IPopups } from '../interfaces/reducers/IAppStore';
import { UserInfoAction } from '../enums/ActionTypes/UserInfoAction';
import { PopupActions } from '../interfaces/IPopupDispatcher';

let initState = { popupSettings: {} } as IPopups;
const userInfo = (state: IPopups = initState, action: {type: string, payload: any}) => {
     switch ( action. type) {
		case PopupActions.ADD_POPUPS:
			return {popupSettings: {...state.popupSettings, ...action.payload} }
		case PopupActions.REMOVE_POPUPS:
			let popupsLeft = {...state.popupSettings};
			(action.payload as Array<string>).forEach(id => {
				delete popupsLeft[id];
			});
			return { popupSettings: popupsLeft }
         default:
             return state;
    }
}
export default userInfo;
import { string } from "prop-types";

var search = location.search.substring(1);
var queryObject = {} as {[key: string]: string};
if(search){
    queryObject = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
}
var type = "admin"
declare global { 
    interface Window { 
        setCookie: (name: string, value: string, days?: number) => void; 
        getCookie: (name: string) => string; 
        eraseCookie: (name: string, paths: Array<string>) => void; 
        apiUrl: string;
        roles: {[key: string]: string}
    }
}
const appConfig = {
    register: !!queryObject["register"],
    apiUrl: window.apiUrl,
    roles: window.roles,
    nomenclatures: window.apiUrl + "Nomenclatures/GetNomenclatures"
};

type ILocalConfig = typeof appConfig;

type IAppConfig = ILocalConfig;

export default appConfig as IAppConfig;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './bootstrap.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import rootReducer from "./rootReducer";
import { Store, createStore, applyMiddleware, Middleware } from 'redux';
import thunk from "redux-thunk";
import Axios from 'axios';
import appConfig from './reducers/appConfig';

const getStore = () => {
    let baseMiddleware: Array<Middleware> = [thunk];
    let store: Store;
    store = createStore(rootReducer, applyMiddleware(...baseMiddleware));
    window.addEventListener("resize", function() {
        store.dispatch({
            type: "Window_resized",
            payload: window.innerHeight < 500 || window.innerWidth < 500
        });
    });
    return store;
}
Axios.get(appConfig.nomenclatures)
.then(response => {
    if(response.data["RepairLotType"]){
        let repairRoles: {[key: string]: string} = {};
        response.data["RepairLotType"].forEach((r: {Id: number, RepairLotTypeName: string})=> {
            repairRoles["REPAIRS_ROLE_" + r.Id.toString()] = "Адм. ремонти " + r.RepairLotTypeName
        })
        appConfig.roles = {...appConfig.roles, ...repairRoles};
        console.log(appConfig.roles)
    }
    ReactDOM.render(<Provider store={getStore()}><App /></Provider>, document.getElementById('root'));
})
.catch(e => {
    ReactDOM.render(<Provider store={getStore()}><App /></Provider>, document.getElementById('root'));
})


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

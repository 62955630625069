import { UserInfoAction } from "../enums/ActionTypes/UserInfoAction";
import appConfig from "../reducers/appConfig";
import axios from "axios";
import { IAppStore, IUserInfo } from '../interfaces/reducers/IAppStore';


declare global { interface Window { setCookie: (name: string, value: string, days?: number) => void; getCookie: (name: string) => string; eraseCookie: (name: string, paths: Array<string>) => void; } }
let interval: NodeJS.Timer;
let signinCallback: (success: boolean) => void;

export const singIn = (username: string, password: string) => (dispatch: (data: any) => void, getStore: () => IAppStore) => {
    dispatch({
        type: UserInfoAction.SET_LOADING,
        payload: true
    })
    axios.post(appConfig.apiUrl + "SmartBurgasAccount/token", {
        Username: username,
        Password: password
    })
    .then(r => {
        var roles = r.data.Roles.map((r: string) => r.toUpperCase())
        let payload = {
            user: {
                token: r.data.Token,
                expires: new Date(new Date().setHours(new Date().getHours() + 12)),
                username: r.data.UserName,
                Name: r.data.Name,

                Contractor: r.data.ContractorId,
                isAdmin: roles.indexOf("ADMIN") != -1,
                isCA: roles.indexOf("CA") != -1,

                isContractorAdmin: roles.indexOf("ContractorAdmin".toUpperCase()) != -1,
                isContractorUser: roles.indexOf("ContractorUser".toUpperCase()) != -1,
            },
            loading: false
        } as IUserInfo;
        dispatchCredentials(getStore(), dispatch, payload)
    })
    .catch(e => {
        dispatch({
            type: UserInfoAction.ERROR_SIGNIN,
            payload: new Error("Грешно потребителско име или парола")
        })
    })
}
let interceptor: number
const dispatchCredentials = (
    store: IAppStore, 
    dispatch: (data: any) => void, 
    payload: IUserInfo, 
    onCompleted?: (success: boolean) => void) => {
        interceptor = axios.interceptors.request.use(
            (config) => {
                config.headers["Authorization"] = `Bearer ${payload.user!.token}`
                return config;
            },
            (config) => {
                return config;
            })
        interval = setInterval(() => {
            if(new Date(payload.user!.expires) <= new Date()){
                dispatch(signOut())
            }
            
        }, 10000)
        window.setCookie("uru", btoa(encodeURIComponent(JSON.stringify(payload))), 1)
        dispatch({
            type: UserInfoAction.SIGN_IN,
            payload: payload
        })
        dispatch({
            type: UserInfoAction.CLEAR_ERROR,
            payload: {}
        })
        

        if(onCompleted){
            onCompleted(true);
        }  
        
}

export const tryRecoverCredentials = (
    callback: (success: boolean) => void) => (dispatch: (data: any) => void, getStore: () => IAppStore) => {
    signinCallback = callback;
    let credentials = window.getCookie("uru");
    let onCompleted = (success: boolean) => {
        if(!success){
            window.eraseCookie("uru", ["/"])
        }
        signinCallback(success)
    }
    if (credentials) {
        credentials = decodeURIComponent(atob(credentials))
        let parsedCredentials = JSON.parse(credentials) as IUserInfo;
        if(parsedCredentials.user && parsedCredentials.user.expires && new Date(parsedCredentials.user.expires) >= new Date()){
            dispatchCredentials(getStore(), dispatch, parsedCredentials, onCompleted)
        }
        else {
            onCompleted(false)
        }
    }
    else {
        onCompleted(false)
    }
}

export const signOut = () => (dispatch: (data: any) => void, getStore: () => IAppStore) => {
    axios.interceptors.request.eject(interceptor);
    clearInterval(interval);
    window.eraseCookie("uru", ["/"]);
    
    dispatch({
        type: UserInfoAction.CLEAR_ERROR,
        payload: {}
    })
    dispatch({
        type: UserInfoAction.SIGN_OUT,
        payload: {}
    })
}
import * as React from 'react';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';

import { IPopupSettings, IAppStore } from './interfaces/reducers/IAppStore';
import { IPopupDispatcher } from './interfaces/IPopupDispatcher';
import { popupDispatcher } from './actions/popupDispatcher';

interface Props {
    popupSettings:{[id: string]: IPopupSettings},
}
const PopupRenderer = (popupSettings:{[id: string]: IPopupSettings}, closePopupFunction: (id: Array<string>) => void, isMobile: boolean): Array<JSX.Element> => {
    const handleStart = (a: any) => {console.log(a)}
    const handleDrag = (a: any) => {console.log(a)}
    const handleStop = (a: any) => {console.log(a)}

    let popups: Array<JSX.Element> = [];
        for(let id in popupSettings){
            popups.push(
                <Draggable key={id}
                    axis={(isMobile || !popupSettings[id].draggableAxis) ? "none" : popupSettings[id].draggableAxis}
                    handle=".modal-popup-header"
                    defaultPosition={{x: 0, y: 0}}
                    position={undefined}
                    onStart={handleStart}
                    onDrag={handleDrag}
                    onStop={handleStop}>
                    <div style={{width: isMobile ? "80%" : popupSettings[id].width, maxHeight: "80%", height: isMobile ? "80%" : popupSettings[id].height}} className="modal-popup-container">
                        <div className="modal-popup-header">
                            {popupSettings[id].header}
                            {popupSettings[id].closeButton ? 
                                <div className="modal-popup-close" 
                                    onClick={() => closePopupFunction([id])}>{popupSettings[id].closeButton!}</div> 
                                : 
                                <div className="modal-popup-close" onClick={() => closePopupFunction([id])}>X</div>}
                        </div>
                        <div className="modal-popup-content">
                            {popupSettings[id].content}
                        </div>
                    </div>
                </Draggable>
            )
        }
    

    return popups;
}
const FixedPopupRenderer = (props: Props & IPopupDispatcher) => {
    let fixedStyle: React.CSSProperties  = {
        position: "fixed", top: "0", bottom: "0", left: "0", right: "0", 
        alignItems: "center", justifyContent: "center", textAlign: "center",
        zIndex: 100, display: (Object.keys(props.popupSettings).length > 0) ? "block" : "none"}
    return (
        <div style={{...fixedStyle}} >
            <div className="modal-popup-background"></div>
            <div  style={{...fixedStyle, display: "flex"}}>
                {PopupRenderer(props.popupSettings, props.removePopups, false)}
            </div>
        </div>
    )
}

const mapStateToProps = (store: IAppStore) => ({
    popupSettings: store.popups.popupSettings
})

export default connect<Props, IPopupDispatcher, {}, IAppStore>(mapStateToProps, {...popupDispatcher})(FixedPopupRenderer as any);


import * as React from "react";
import appConfig from "../reducers/appConfig";

interface Props {
    onSelectionChanged: (newSelection: string[]) => void;
    selectedRoles: string[];
}

export const RolesSelector = (props: Props) => {
    let checkboxes: JSX.Element[] = [];
    let selectedRoles = [...props.selectedRoles];
    console.log(appConfig.roles);
    for (let role in appConfig.roles) {
        checkboxes.push(
            <div key={role} style={{ display: "flex", alignItems: "center", margin: "10px" }}>
                <input
                    type="checkbox"
                    style={{ transform: "scale(1.6)", marginRight: "4px" }}
                    checked={selectedRoles.indexOf(role) != -1}
                    onChange={() => {
                        if (selectedRoles.indexOf(role) != -1) {
                            selectedRoles.splice(selectedRoles.indexOf(role), 1)
                        }
                        else {
                            selectedRoles.push(role)
                        }
                        props.onSelectionChanged(selectedRoles)
                    }}></input>
                <div>{appConfig.roles[role]}</div>
            </div>
        )
    }
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <h5>Изберете роли:</h5>
            {checkboxes}
        </div>
    );
}
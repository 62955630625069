import * as React from "react";
import axios from "axios";
import appConfig from "../reducers/appConfig";
import { IUser } from "../interfaces/reducers/IAppStore";
import { UserViewModel } from "../interfaces/UserViewMode";
import { RolesSelector } from "./RolesSelector";
import { userInfo } from "os";

interface State {
    errorMsg: string;
    success: boolean;
    selectedRoles: string[];
}

interface Props{
    user: UserViewModel;
}

class EditRoles extends React.Component<Props, State> {
    constructor(props: Props){
        super(props);
        this.state = {
            errorMsg: "",
            success: false,
            selectedRoles: props.user.Roles
        }
    }

    changeRoles = () => {
        this.setState({...this.state, errorMsg: "", success: false});
        if(this.state.selectedRoles.length == 0){
            this.setState({...this.state, errorMsg: "Моля, изберете поне една роля", success: false});
            return;
        }
        return new Promise((resolve, reject) => {
            axios.post(appConfig.apiUrl + "SmartBurgasAccount/setRoles", {
                Roles: this.state.selectedRoles,
                UserName: this.props.user.Username
            })
            .then(r => {
                this.setState({...this.state, errorMsg: "", success: true})
            })
            .catch((e: any) => {
                this.setState({...this.state, errorMsg: "Възникна грешка", success: false})
            })
        })
    }
    render(){
        if(this.state.success){
            return (
                <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <div style={{color: "green"}}>{`Успешно редактирани роли на потребител ${this.props.user.Username}`}</div>
                    <button className="btn btn-success" onClick={() => this.setState({...this.state, errorMsg: "", success: false})}>Редактирай отново</button>
                </div>
            )
        }
        return(
            <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                <RolesSelector 
                    selectedRoles={this.state.selectedRoles} 
                    onSelectionChanged={(selection) => {
                        this.setState({...this.state, selectedRoles: selection})
                    }}></RolesSelector>
                {this.state.errorMsg ? <div style={{color: "red"}}>{this.state.errorMsg}</div> : null}
                <button className="btn btn-success" onClick={() => {
                     this.changeRoles();
                }}>Запази промените</button>
            </div>
        )
    }
}

export default EditRoles;